import Button from '@mui/material/Button';
import { RiSendPlaneFill } from "react-icons/ri";
import COLOR from "../config/Color";

function CustomButton(props) {
    return (
        <Button onClick={()=>props.QuestionData()}
                disabled={props.isLoading}
                variant="outlined"
                className="pretendard-regular"
                style={{backgroundColor: COLOR.lightBlue,
        }} endIcon={<RiSendPlaneFill />}>
            {props.message}
        </Button>
    );
}

export default CustomButton;

