import styled from "styled-components";
import HexToRgba from "../config/HexToRgba";
import COLOR from "../config/Color";

export const StyledBox = styled.div`
    padding: 30px 26px;
    height: auto;
    box-shadow: 0 0 2px 2px ${HexToRgba(COLOR.skyBlue, 0.3)};
    color: ${COLOR.grey};
    background-color: ${COLOR.white};
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: auto;
    margin-bottom: 322px;
`;
export const StyledInput = styled.div`
    padding-top: 24px;
    height: 200px;
    box-shadow: 0 0 2px 2px ${HexToRgba(COLOR.skyBlue, 0.3)};
    margin-top: 8px;
    margin-bottom: 48px;
    background: ${HexToRgba(COLOR.lightBlue, 0.3)};
    backdrop-filter: blur(10px);
`;

export const Input = styled.input`
    color: ${COLOR.grey};
`;