import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import COLOR from "../config/Color"

function CustomNavbar() {
    return (
        <Navbar style={{height: "120px", background: COLOR.lightBlue}}>
            <Container>
                <Navbar.Brand href="/" style={{fontSize: "52px", color: COLOR.deepBlue}} className="audiowide-regular">
                    AIIA-GPT
                </Navbar.Brand>
                <Nav className="justify-content-end" activeKey="/home">
                    <Nav.Item>
                        <Nav.Link href="https://aiia-gcu.com/" style={{fontSize: "28px", color: COLOR.skyBlue}} className="pretendard-regular">About AIIA</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;