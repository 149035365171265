/*eslint-disable*/
import './App.css';
import CustomNavbar from "./components/CustomNavbar";
import MainBody from "./components/MainBody";
import InputArea from './components/InputArea';
import Result from './components/Result';
import {useState} from "react";
function App() {
    let [answer, setAnswer] = useState('');
    return (
        <div className="App">
            <CustomNavbar/>
            <MainBody/>
            <InputArea setAnswer={setAnswer} answer={answer}/>
            <Result answer={answer}/>
        </div>
    );
}

export default App;
