import '../App.css';
import { StyledBox } from './StyledComponents';
import ReactMarkdown from 'react-markdown';

function Result({ answer }) {

    answer = answer.substring(1, answer.length - 1);

    const fuckingAnswer = answer
        ? answer
            .replace(/\\n/g, '\n')
            .replace(/\\t/g, '&emsp;')
            .replace(/\\/g, '')
        : '';

    const formattedAnswer = <ReactMarkdown>{fuckingAnswer}</ReactMarkdown>

    return (
        <div className="center">
            <StyledBox className="box" style={{ fontSize: 'medium' }}>
                {formattedAnswer}
            </StyledBox>
        </div>
    );
}

export default Result;
