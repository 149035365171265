import React, {useState} from 'react';
import axios from 'axios';
import '../App.css';
import COLOR from "../config/Color";
import HexToRgba from "../config/HexToRgba";
import {StyledInput} from "./StyledComponents";
import CustomButton from "./CustomButton";

function InputArea({setAnswer}) {
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    let QuestionData = async () => {
        if (text.trim() === '') return;

        setIsLoading(true);

        try {
            let response = await axios.post('https://gpt-be.aiia-gcu.com/ask', {
                question: text
            });
            console.log("서버 응답:", response.data);
            setAnswer(response.data.answer);

        } catch (error) {
            console.error('Error', error);
            setAnswer("다시 입력해주세요.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="center">
            <StyledInput
                className="box"
                boxShadowColor={HexToRgba(COLOR.skyBlue, 0.3)}
            >
                <textarea
                    className="input"
                    value={text}
                    rows={5}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="AIIA에 대해 궁금한 점을 물어보세요!"
                    color={COLOR.grey}
                    style={{fontSize: "medium", overflow: "auto", resize: "none", width: "99%"}}
                />

                <div className="button">
                    <CustomButton message={isLoading ? "답변 중" : "질문하기"}
                                  isLoading={isLoading}
                                  QuestionData={QuestionData}/>
                </div>


            </StyledInput>
        </div>
    );
}

export default InputArea;
