/*
색상 투명도 넣을 때 헥사코드 대신 rgba 써야 함
해당 function 으로 COLOR 불러오면 rgba 변환 가능
ex) ${HexToRgba(COLOR.skyBlue, 0.3)}
 */

function HexToRgba(hex, alpha) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
export default HexToRgba;